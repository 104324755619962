// main imports
import * as React from "react";

// plugin imports
import Warp from "warpjs";

// style imports
import "./SVGWave.scss";

// svg imports

// components imports
// component
export default function SVGWave({ children, id }) {
	React.useEffect(() => {
		const svg = document.getElementById(id);

		const warp = new Warp(svg);

		warp.transform(([x, y]) => [x, y, y]);

		let offset = 0;
		function animate() {
			warp.transform(([x, y, oy]) => [
				x,
				oy + 20 * Math.sin(x / 100 + offset),
				oy,
			]);
			offset += 0.015;

			window.setTimeout(() => {
				requestAnimationFrame(animate);
			}, 30);
		}

		animate();
	}, [id]);

	return <div>{children}</div>;
}
