import React from "react";

import { useI18next } from "gatsby-plugin-react-i18next";

export default function Content({ name, db }) {
	const { language } = useI18next();
	const defaultLang = "en";

	// ERROR for debugging
	// if (db[language][name] === undefined) {
	// 	console.error(`Missing text/translation. Could not find ${name} on:`);
	// 	console.log(db[language]);
	// }

	return <>{db[language][name] || db[defaultLang][name]}</>;
}

export function getContent(name, db, lng) {
	return db[lng][name] || db.en[name];
}
