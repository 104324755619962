// main imports
import * as React from "react";
import { Link, useI18next } from "gatsby-plugin-react-i18next";

// plugin imports

// style imports
import "./Layout.scss";
import "../../styles/main.scss";

// svg imports
import Logo from "../../svg/logo.svg";
import LogoW from "../../svg/logoW.svg";
import BgWavesSVG from "../../svg/bgWaves.svg";

// components imports
import ClientOnly from "../CookiesBanner/ClientOnly";
import CookiesBanner from "../CookiesBanner/CookiesBanner";
import MenuMobile from "./MenuMobile";
import MenuDesktop from "./MenuDesktop";
import Footer from "./Footer";
import Transition from "./Transitions";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import { CSSTransition } from "react-transition-group";
import SVGWave from "../BgWaves/SVGWave";

// component
export default function Layout({ location, children }) {
	const { t } = useI18next();

	const transitionTimeout = 250;

	const [menuShow, setMenuShow] = React.useState(false);
	const [screenTop, setScreenTop] = React.useState(true);

	const windowGlobal = typeof window !== "undefined" && window;
	const documentGlobal = typeof document !== "undefined" && document;

	const menuItems = [
		{ link: "/cleaning", text: t("Cleaning") },
		{ link: "/protection", text: t("Protection") },
		{ link: "/products", text: t("Products") },
		{ link: "/about", text: t("About") },
		{ link: "/blog", text: t("Blog") },
		{ link: "/contact", text: t("Contact") },
	];

	// Toggle menu bg on window top
	const scrollTrigger = 50;
	const handleMenuBg = (e) => {
		const scrollPos = e?.target?.scrollingElement?.scrollTop;
		scrollPos > scrollTrigger ? setScreenTop(false) : setScreenTop(true);
	};
	React.useEffect(() => {
		const windowGlobal = typeof window !== "undefined" && window;
		windowGlobal.pageYOffset > scrollTrigger
			? setScreenTop(false)
			: setScreenTop(true);

		windowGlobal.addEventListener("scroll", handleMenuBg);
		return () => windowGlobal.removeEventListener("scroll", handleMenuBg);
	}, []);

	// Disable scroll on body if menu is open
	React.useEffect(() => {
		menuShow === true
			? documentGlobal.body.classList.add("menuShow")
			: documentGlobal.body.classList.remove("menuShow");
	}, [menuShow, documentGlobal]);

	// Disable animations on window resize
	React.useEffect(() => {
		const resizeAnimationAddRemoveClass = () => {
			let resizeTimer;
			documentGlobal.body.classList.add("resize-animation-stopper");
			clearTimeout(resizeTimer);
			resizeTimer = setTimeout(() => {
				documentGlobal.body.classList.remove("resize-animation-stopper");
			}, 400);
		};

		windowGlobal.addEventListener("resize", resizeAnimationAddRemoveClass);

		return () =>
			windowGlobal.removeEventListener("resize", resizeAnimationAddRemoveClass);
	}, [windowGlobal, documentGlobal]);

	// Set section
	const [section, setSection] = React.useState(undefined);
	React.useEffect(() => {
		setTimeout(() => setSection(location?.pathname), transitionTimeout);
	}, [location]);

	// console.log(section);

	return (
		<>
			<nav
				className={`${
					section && section.endsWith("products/") ? "negative" : ""
				} ${screenTop ? "" : "menuBg"}`}
			>
				<Link to="/" className="navLogo">
					<Logo className="logo" />
					<LogoW className="negativeLogo" />
				</Link>
				<MenuMobile
					menuItems={menuItems}
					menuShow={menuShow}
					setMenuShow={setMenuShow}
				/>
				<MenuDesktop menuItems={menuItems} />
			</nav>

			<main
				className={`${
					section && section.endsWith("products/") ? "negative" : ""
				} ${location?.action ? "animatePageTransition" : ""}`}
			>
				<div id="top" />
				<ClientOnly>
					<CookiesBanner />
				</ClientOnly>
				<CSSTransition
					in={
						section === "/" || section === "/es/" || section === "/ca/"
							? false
							: true
					}
					classNames="fade"
					timeout={500}
					appear
					unmountOnExit
				>
					<SVGWave id="bgWaves-lines">
						<BgWavesSVG className={`bgWaves`} />
					</SVGWave>
				</CSSTransition>
				<Transition location={location}>
					{/* we need gatsby-plugin-layout for this to work */}
					<div className="contents">{children}</div>
				</Transition>
				<ScrollToTop />
			</main>

			<Footer section={section} />
		</>
	);
}
