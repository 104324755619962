// main imports
import * as React from "react";

// plugin imports
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { CSSTransition } from "react-transition-group";

// style imports
import "./ScrollToTop.scss";

// svg imports
import Arrow from "../../svg/iconTopArrow.svg";

// components imports

export default function ScrollToTop() {
	const [visible, setVisible] = React.useState(false);

	// Prevent memory leak
	React.useEffect(() => {
		const toggleVisible = () => {
			if (
				typeof document !== "undefined" &&
				document?.documentElement?.scrollHeight > 2500
			) {
				const scrolled = document?.documentElement?.scrollTop;
				const scrollTrigger = 800;
				if (scrolled > scrollTrigger) {
					setVisible(true);
				} else if (scrolled <= scrollTrigger) {
					setVisible(false);
				}
			}
		};

		if (typeof window !== "undefined") {
			window.addEventListener("scroll", toggleVisible);
		}

		// On component unmount we hide the icon
		return () => {
			setVisible(false);
			window.removeEventListener("scroll", toggleVisible);
		};
	}, []);

	// console.log("Visible: " + visible);

	return (
		<CSSTransition
			in={visible}
			classNames="fade"
			timeout={400}
			appear
			unmountOnExit
		>
			<div id="scrollToTop">
				<AnchorLink className="button" to={"#top"}>
					<span>Scroll</span>
					<Arrow className="icon" />
				</AnchorLink>
			</div>
		</CSSTransition>
	);
}
