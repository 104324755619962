// main imports
import * as React from "react";

// plugin imports
import { useLocation } from "@reach/router";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";

// style imports
import "./CookiesBanner.scss";
import { Trans, useTranslation } from "react-i18next";
import ToggleButton from "./ToggleButton";

// components imports

// svg imports

// function exports for use in other pages
export const addCookies = (date, location) => {
	// console.log("addCookies running");
	const acceptAppendix =
		"=true;path=/;SameSite=none;Secure;expires=" + date.toGMTString();
	// console.log(acceptAppendix);

	if (typeof document != "undefined") {
		document.cookie = "cookiesStored" + acceptAppendix;
		document.cookie = "cookiesAccepted" + acceptAppendix;
		document.cookie = "gatsby-gdpr-google-analytics" + acceptAppendix;
		document.cookie = "gatsby-gdpr-facebook-pixel" + acceptAppendix;
		document.cookie = "gatsby-gdpr-google-tagmanager" + acceptAppendix;
		document.cookie = "gatsby-gdpr-tiktok-pixel" + acceptAppendix;
		document.cookie = "gatsby-gdpr-hotjar" + acceptAppendix;
		document.cookie = "gatsby-gdpr-linked-in" + acceptAppendix;

		// console.log("Initializing tracking");
		initializeAndTrack(location);
	}
};
export const rejectCookies = (date) => {
	// console.log("rejectCookies running");
	const rejectAppendix =
		"=false;path=/;SameSite=none;Secure;expires=" + date.toGMTString();

	if (typeof document != "undefined") {
		document.cookie =
			"cookiesStored" +
			"=true;path=/;SameSite=none;Secure;expires=" +
			date.toGMTString();

		document.cookie = "cookiesAccepted" + rejectAppendix;
		document.cookie = "gatsby-gdpr-google-analytics" + rejectAppendix;
		document.cookie = "gatsby-gdpr-facebook-pixel" + rejectAppendix;
		document.cookie = "gatsby-gdpr-google-tagmanager" + rejectAppendix;
		document.cookie = "gatsby-gdpr-tiktok-pixel" + rejectAppendix;
		document.cookie = "gatsby-gdpr-hotjar" + rejectAppendix;
		document.cookie = "gatsby-gdpr-linked-in" + rejectAppendix;
	}
};
export const getCookie = (name) => {
	if (typeof document != "undefined") {
		// console.log(document);
		var v = document?.cookie?.match("(^|;) ?" + name + "=([^;]*)(;|$)");
		// console.log(v);
		if (v) {
			if (v[2] === "true") {
				return true;
			} else if (v[2] === "false") {
				return false;
			} else {
				return v[2];
			}
		}
	}
	return null;
};
export const resetCookiesPanel = () => {
	// console.log("rejectCookies running")
	const oneYear = 365 * 24 * 60 * 60 * 1000; //one year in seconds, to set expiry date for the cookie
	const rejectAppendix =
		"=false;path=/;SameSite=none;Secure;Max-Age=" + oneYear;

	if (typeof document != "undefined") {
		document.cookie = "cookiesStored" + rejectAppendix;
	}
};

// component
export default function CookieBanner() {
	const { t } = useTranslation("cookiesBanner");

	const [funcCookies, setFuncCookies] = React.useState(true);
	const [mktCookies, setMktCookies] = React.useState(
		getCookie("cookiesAccepted")
	);
	const [cookiesStored, setCookiesStored] = React.useState(
		getCookie("cookiesStored")
	);

	const location = useLocation();

	// console.log(cookiesStored);

	// console.log("cookiesStored is: " + cookiesStored);
	// console.log("cookiesAccepted is: " + mktCookies);

	React.useEffect(() => {
		// console.log("useEffect running");
		// we have to do this inside the useEffect to prevent Date mismatches from server to client
		const expiry = new Date();
		expiry.setTime(expiry.getTime() + 365 * 24 * 60 * 60 * 1000);

		if (mktCookies && cookiesStored) {
			// if cookies are accepted and have not been previously added, store them
			// console.log("Accepting cookies");
			addCookies(expiry, location);
		}

		if (!mktCookies && cookiesStored) {
			// if cookies are disabled, remove them
			// console.log("Rejecting cookies");s
			rejectCookies(expiry);
		}
	}, [mktCookies, cookiesStored, location]);

	return (
		<>
			{!cookiesStored && (
				<div className={`cookiesBanner negative`}>
					<h2>
						<Trans ns="cookiesBanner">Cookies</Trans>
					</h2>
					<p>
						<Trans ns="cookiesBanner">
							Utilizamos cookies para optimizar tu experiencia en nuestra web.
						</Trans>
					</p>
					<ToggleButton
						label={t("Funcionales")}
						checked={funcCookies}
						onClick={() => setFuncCookies(true)}
					/>
					<ToggleButton
						label={t("Marketing")}
						checked={mktCookies}
						onClick={() => {
							setMktCookies(!mktCookies);
						}}
					/>
					<button
						onClick={() => setCookiesStored(true)}
						className="saveConfigButton"
					>
						<Trans ns="cookiesBanner">Guardar configuración</Trans>
					</button>
					<button
						onClick={() => {
							setCookiesStored(true);
							setMktCookies(true);
						}}
						className="acceptAllButton button"
					>
						<h4>
							<Trans ns="cookiesBanner">ACEPTAR TODAS</Trans>
						</h4>
					</button>
				</div>
			)}
		</>
	);
}
