module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"G-BVMTYQ2T32","anonymize":true},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Nautiel web","short_name":"Nautiel","start_url":"/","lang":"en","background_color":"#011527","theme_color":"#fff","display":"minimal-ui","icon":"src/svg/favicon.svg","localize":[{"start_url":"/en/","lang":"en","name":"Nautiel. Yacht cleaning and protection","short_name":"Nautiel","description":"Yacht cleaning, Textile treatments, maintenance and conservation from 1991."},{"start_url":"/es/","lang":"es","name":"Nautiel. Limpieza y protección de yates","short_name":"Nautiel","description":"Limpieza de yates, tratamientos textiles, mantenimiento y conservación desde 1991."},{"start_url":"/ca/","lang":"ca","name":"Nautiel. Neteja i protecció de iots","short_name":"Nautiel","description":"Neteja de iots, tractaments tèxtils, manteniment i conservació des-de 1991."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1d83292ef88f5376944a3ebecfafb228"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/components/Layout/Layout.js"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1800,"linkImagesToOriginal":false,"backgroundColor":"none","disableBgImageOnAlpha":true,"withWebp":true,"showCaptions":false,"markdownCaptions":false,"quality":50,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"runtimeCaching":[{"urlPattern":{},"handler":"NetworkOnly"}]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","es","ca"],"defaultLanguage":"en","siteUrl":"https://www.nautiel.com/","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-200,"duration":750},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
