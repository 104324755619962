// main imports
import * as React from "react";

// plugin imports

// style imports
import "./MenuDesktop.scss";
import { Link } from "gatsby-plugin-react-i18next";

// svg imports

// components imports

// component
export default function MenuDesktop({ menuItems }) {
	return (
		<div className={`menuDesktop`}>
			{menuItems.map((item, i) => (
				<Link
					to={item?.link}
					className="menuIt"
					key={item?.text}
					activeClassName="activeSection"
				>
					<span className="decoNum">0{i + 1}</span>
					<h3>{item?.text}</h3>
				</Link>
			))}
		</div>
	);
}
