// main imports
import * as React from "react";

// plugin imports
// import { useStaticQuery, graphql } from "gatsby";
import { Trans, useTranslation } from "react-i18next";
import { send } from "@emailjs/browser";
import { CSSTransition, SwitchTransition } from "react-transition-group";
// import { PhoneInput } from "react-international-phone";

// style imports
import "react-international-phone/style.css";
import "./ContactForm.scss";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

// components imports

// svg imports
import Loader from "../../svg/iconLoader.svg";
import Arrow from "../../svg/iconArrow.svg";
import { Link } from "gatsby-plugin-react-i18next";
// import Content from "../Content/Content";

//component
export default function ContactForm() {
	// const data = useStaticQuery(graphql`
	// 	query {
	// 		content: markdownRemark(fileAbsolutePath: { regex: "/footer.md$/" }) {
	// 			frontmatter {
	// 				ca {
	// 					formTitle
	// 				}
	// 				en {
	// 					formTitle
	// 					formSuccess
	// 				}
	// 				es {
	// 					formTitle
	// 				}
	// 			}
	// 		}
	// 	}
	// `);

	// const content = data?.content?.frontmatter;

	const { t } = useTranslation();
	const [loading, setLoading] = React.useState(false);
	const [error, setError] = React.useState(false);
	const [sent, setSent] = React.useState(false);
	const [toSend, setToSend] = React.useState({
		from_name: "",
		from_phone: "",
		from_email: "",
		message: "",
		company: "",
		address: "",
		boat_type: "",
		boat_model: "",
		boat_length: "",
		service_type: "",
		data_protection: "No",
	});
	const [phone, setPhone] = React.useState("");
	const [formStep, setFormStep] = React.useState(1);

	// console.log(toSend);

	const handleSubmit = (e) => {
		e.preventDefault();

		setLoading(true);
		setError(false);

		// Si le das a enviar sin rellenar datos adicionales simplemente te lleva al mensaje final sin reenviar nada
		if (
			formStep === 2 &&
			toSend.message === "" &&
			toSend.company === "" &&
			toSend.address === "" &&
			toSend.boat_type === "" &&
			toSend.boat_model === "" &&
			toSend.boat_length === "" &&
			toSend.service_type === ""
		) {
			setTimeout(() => {
				setSent(true);
				setLoading(false);
			}, 400);
			return;
		}
		send(
			process.env.GATSBY_MAILJS_SERVICE_ID,
			process.env.GATSBY_MAILJS_TEMPLATE_ID,
			toSend,
			process.env.GATSBY_MAILJS_USER_ID
		)
			.then((response) => {
				formStep === 1 ? setFormStep(2) : setSent(true);
				setLoading(false);
				console.log("Datos enviados", response.status, response.text);
			})
			.catch((err) => {
				setError(true);
				setLoading(false);
				console.log("Error enviando datos", err);
			});
	};

	const handleChange = (e) => {
		// console.log(e);
		setSent(false);
		if (e.target.type === "checkbox") {
			return setToSend((prevState) => {
				return {
					...prevState,
					[e.target.name]: e.target.checked ? "Si" : "No",
				};
			});
		}
		return setToSend((prevState) => {
			return { ...prevState, [e.target.name]: e.target.value };
		});
	};

	// handle Change of phone component
	React.useEffect(() => {
		setToSend((prevState) => {
			return { ...prevState, from_phone: phone };
		});
	}, [phone]);

	// console.log(
	// 	process.env.GATSBY_MAILJS_SERVICE_ID,
	// 	process.env.GATSBY_MAILJS_TEMPLATE_ID,
	// 	process.env.GATSBY_MAILJS_USER_ID
	// );

	return (
		<SwitchTransition mode="out-in">
			<CSSTransition key={loading} timeout={400} classNames="fade">
				<div className="contactForm">
					{!loading && !error && !sent ? (
						formStep === 1 ? (
							<div>
								<div>
									<form
										action=""
										onSubmit={handleSubmit}
										onChange={handleChange}
										method="POST"
										target="_blank"
										className={`${sent ? "sent" : ""}`}
									>
										<div className="formFields">
											<div>
												<label htmlFor="from_name">
													<Trans>Full name</Trans>
												</label>
												<input
													type="text"
													placeholder={t("Nombre")}
													id="from_name"
													name="from_name"
													title=" "
													required
												/>
												<label htmlFor="from_phone">
													<Trans>Phone</Trans>
												</label>
												<PhoneInput
													international
													defaultCountry="ES"
													value={phone}
													onChange={setPhone}
													withCountryCallingCode={true}
													placeholder={t("Phone")}
												/>
												<label htmlFor="from_email">
													<Trans>Email</Trans>
												</label>
												<input
													type="email"
													placeholder={t("Email")}
													id="from_email"
													name="from_email"
													title=" "
													required
												/>
											</div>
											<div>
												<label htmlFor="message">
													<Trans>Message</Trans>
												</label>
												<textarea
													placeholder={t("Message")}
													name="message"
													id="message"
													title=" "
												/>
											</div>
										</div>

										<div className="formBottom">
											<div className="dataProtection">
												<p className="dataDisclaimer">
													<Trans>
														We will use your data to answer queries, send
														commercial communications and conduct statistical
														analysis.
													</Trans>{" "}
													<Link to="/legal/privacy">+INFO</Link>
												</p>
												<div className="dataCheck">
													<input
														type="checkbox"
														name="data_protection"
														id="data_protection"
														required
													/>
													<label htmlFor="data_protection">
														<p className="small">
															<Trans>
																I agree to the processing of my data.
															</Trans>
														</p>
													</label>
												</div>
											</div>

											<div className="formSubmit">
												<button type="submit" className="button">
													<span>
														<Trans>Send</Trans>
													</span>
													<Arrow />
												</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						) : (
							<div>
								<div>
									<div className="formMessage">
										<h2>
											<Trans>Submission sent</Trans>
										</h2>
										<p>
											<Trans>
												We have received your request and we will get in touch
												with you at the email
											</Trans>{" "}
											{toSend.from_email || t("provided")}.
										</p>
										<p>
											<Trans>
												To ensure we can attend your request as quickly as
												posible, you can send us additional details below.
											</Trans>
										</p>
									</div>

									<form
										action=""
										onSubmit={handleSubmit}
										onChange={handleChange}
										method="POST"
										target="_blank"
										className={`${sent ? "sent" : ""}`}
									>
										<div className="formFields">
											<div>
												<label htmlFor="company">
													<Trans>Company</Trans>
													<span className="optional">
														<Trans>optional</Trans>
													</span>
												</label>
												<input
													type="text"
													placeholder={t("Company")}
													id="company"
													name="company"
													title=" "
												/>
												<label htmlFor="address">
													<Trans>Address</Trans>
													<span className="optional">
														<Trans>optional</Trans>
													</span>
												</label>
												<input
													type="address"
													placeholder={t("Address")}
													id="address"
													name="address"
													title=" "
												/>
												<label htmlFor="service_type">
													<Trans>Service</Trans>
													<span className="optional">
														<Trans>optional</Trans>
													</span>
												</label>
												<div className="radioInput">
													<div className="radioLabel">
														<input
															type="radio"
															id="Cleaning"
															name="service_type"
															value="Cleaning"
														/>
														<label htmlFor="Cleaning" className="radioLabel">
															<Trans>Cleaning</Trans>
														</label>
													</div>

													<div className="radioLabel">
														<input
															type="radio"
															id="Protection"
															name="service_type"
															value="Protection"
														/>
														<label htmlFor="Protection" className="radioLabel">
															<Trans>Protection</Trans>
														</label>
													</div>

													<div className="radioLabel">
														<input
															type="radio"
															id="otherSvc"
															name="service_type"
															value="Other"
														/>
														<label htmlFor="otherSvc" className="radioLabel">
															<Trans>Other</Trans>
														</label>
													</div>
												</div>
											</div>
											<div>
												<label htmlFor="boat_type">
													<Trans>Boat type</Trans>
													<span className="optional">
														<Trans>optional</Trans>
													</span>
												</label>
												<div className="radioInput">
													<div className="radioLabel">
														<input
															type="radio"
															id="Super Yacht"
															name="boat_type"
															value="Super Yacht"
														/>
														<label htmlFor="Super Yacht" className="radioLabel">
															<Trans>Super Yacht</Trans>
														</label>
													</div>

													<div className="radioLabel">
														<input
															type="radio"
															id="Motor Yacht"
															name="boat_type"
															value="Motor Yacht"
														/>
														<label htmlFor="Motor Yacht" className="radioLabel">
															<Trans>Motor Yacht</Trans>
														</label>
													</div>

													<div className="radioLabel">
														<input
															type="radio"
															id="Sailboat"
															name="boat_type"
															value="Sailboat"
														/>
														<label htmlFor="Sailboat" className="radioLabel">
															<Trans>Sailboat</Trans>
														</label>
													</div>

													<div className="radioLabel">
														<input
															type="radio"
															id="Tender"
															name="boat_type"
															value="Tender"
														/>
														<label htmlFor="Tender" className="radioLabel">
															<Trans>Tender</Trans>
														</label>
													</div>

													<div className="radioLabel">
														<input
															type="radio"
															id="Other"
															name="boat_type"
															value="Other"
														/>
														<label htmlFor="Other" className="radioLabel">
															<Trans>Other</Trans>
														</label>
													</div>
												</div>

												<label htmlFor="boat_model">
													<Trans>Boat model</Trans>
													<span className="optional">
														<Trans>optional</Trans>
													</span>
												</label>
												<input
													type="boat_model"
													placeholder={t("Boat model")}
													id="boat_model"
													name="boat_model"
													title=" "
												/>
												<label htmlFor="boat_model">
													<Trans>Boat length</Trans>
													<span className="optional">
														<Trans>optional</Trans>
													</span>
												</label>
												<input
													type="boat_length"
													placeholder={t("Boat length")}
													id="boat_length"
													name="boat_length"
													title=" "
												/>
											</div>
										</div>

										<div className="formBottom">
											<div />

											<div className="formSubmit">
												<button type="submit" className="button">
													<span>
														<Trans>Send extra details</Trans>
													</span>
													<Arrow />
												</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						)
					) : (
						<div className="formMessage">
							{loading && (
								<div className="loaderContainer">
									<Loader />
									<h4>
										<Trans>Sending</Trans>
									</h4>
								</div>
							)}
							{error && (
								<div className="errorContainer">
									<h2>
										<Trans>Error</Trans>
									</h2>
									<p>
										<Trans>
											There was an error sending your submission, please try
											again in a few moments.
										</Trans>
									</p>
								</div>
							)}
							{sent && !error && (
								<div className="successContainer">
									<h2>
										<Trans>Submission sent</Trans>
									</h2>
									<p>
										<Trans>We'll get in touch with you at the email</Trans>{" "}
										{toSend.from_email || t("provided")}.
									</p>
									{/* <p>
										<Content name="formSuccess" db={content} />
									</p> */}
								</div>
							)}
						</div>
					)}
				</div>
			</CSSTransition>
		</SwitchTransition>
	);
}
