// Parallax
import React from "react";
import { ParallaxProvider } from "react-scroll-parallax";

export const wrapPageElement = ({ element }) => {
	return <ParallaxProvider>{element}</ParallaxProvider>;
};

export const shouldUpdateScroll = ({ routerProps }) => {
	// if going through link navigation, scroll to top on middle of the animation
	// console.log(routerProps);
	if (routerProps?.location?.action) {
		window.setTimeout(() => window.scrollTo(0, 0), 500);
		return false;
	}
	// if going through history navigation, go directly to the prev scroll position
	return true;
};
