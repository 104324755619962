import React from "react";
import {
	TransitionGroup,
	Transition as ReactTransition,
} from "react-transition-group";

import "./Transitions.scss";

export default function Transition({ children, location }) {
	return (
		<TransitionGroup>
			<ReactTransition
				key={location?.pathname || ""}
				timeout={{
					enter: 500,
					exit: 500,
				}}
			>
				{(state) => <div className={state}>{children}</div>}
			</ReactTransition>
		</TransitionGroup>
	);
}
