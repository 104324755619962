// main imports
import * as React from "react";

// plugin imports
import { useStaticQuery, graphql } from "gatsby";
import { Link, Trans, useI18next } from "gatsby-plugin-react-i18next";

// style imports
import "./Footer.scss";

// svg imports
import Logo from "../../svg/logoW.svg";
import Arrow from "../../svg/iconArrow.svg";
import KDBanner from "../../svg/KDBanner.svg";

// components imports
import Content from "../Content/Content";
import ContactForm from "../ContactForm/ContactForm";
import ClientOnly from "../CookiesBanner/ClientOnly";

// component
export default function Footer({ section }) {
	const { language, languages, originalPath } = useI18next();

	const data = useStaticQuery(graphql`
		query {
			content: markdownRemark(fileAbsolutePath: { regex: "/footer.md$/" }) {
				frontmatter {
					ca {
						formIntro1
						formIntro2
						formIntro3
						formTitle

						rrssTitle
					}
					en {
						formIntro1
						formIntro2
						formIntro3
						formTitle

						rrssTitle
						fbLink
						instaLink
						linkedInLink
						ytLink
						xLink

						tel
						address1
						address2
						address3
					}
					es {
						formIntro1
						formIntro2
						formIntro3
						formTitle

						rrssTitle
					}
				}
			}
		}
	`);

	const content = data?.content?.frontmatter;

	const rrssLinks = [
		{
			icon: "fb",
			title: "Facebook",
			link: content.en.fbLink,
		},
		{
			icon: "ig",
			title: "Instagram",
			link: content.en.instaLink,
		},
		{
			icon: "lIn",
			title: "LinkedIn",
			link: content.en.linkedInLink,
		},
		// {
		// 	icon: "yt",
		// 	title: "Youtube",
		// 	link: content.en.ytLink,
		// },
		// {
		// 	icon: "x",
		// 	title: "X",
		// 	link: content.en.xLink,
		// },
	];

	return (
		<footer
			className={`${
				section === "/" || section === "/es/" || section === "/ca/"
					? "footerLanding"
					: ""
			}`}
		>
			<div id="contactForm">
				<div>
					<h3 className="formTitle">
						<Content name="formTitle" db={content} />
					</h3>
					{section && section.endsWith("contact/") && (
						<div className="contactIntro">
							<p>
								<Content name="formIntro1" db={content} />
							</p>
							<p>
								<Content name="formIntro2" db={content} />
							</p>
							<p>
								<Content name="formIntro3" db={content} />
							</p>
						</div>
					)}

					<ContactForm />
				</div>
			</div>

			<div id="rrssLinks">
				<Content name="rrssTitle" db={content} />
				<div>
					{rrssLinks.map((rrss) => {
						if (rrss.link) {
							return (
								<a href={rrss.link} className="button" key={rrss.title}>
									<span>{rrss.title}</span>
									<Arrow />
								</a>
							);
						}
						return null;
					})}
				</div>
			</div>

			<div id="langAndLegal">
				<div id="langSelector">
					<ClientOnly>
						{languages?.map((lng, i) => (
							<span key={lng}>
								<Link
									to={originalPath}
									language={lng}
									className={lng === language ? "activeLang" : ""}
								>
									{lng}
								</Link>
							</span>
						))}
					</ClientOnly>
				</div>
				<br />
				<div id="legalLinks">
					<Link to="/legal/notice">
						<Trans>Legal notice</Trans>
					</Link>
					<Link to="/legal/privacy">
						<Trans>Privacy policy</Trans>
					</Link>
					<Link to="/legal/cookies">
						<Trans>Cookies policy</Trans>
					</Link>
				</div>
			</div>

			<div id="contactDetails">
				<div>
					<Content name="tel" db={content} />
				</div>
				<br />
				<div>
					<Content name="address1" db={content} />
				</div>
				<div>
					<Content name="address2" db={content} />
				</div>
				<div>
					<Content name="address3" db={content} />
				</div>
			</div>

			<div className="footerLogo">
				<Link to="/">
					<Logo />
				</Link>
				<span className="credits">
					<div>© {new Date().getFullYear()}</div>
					<div>Nautiel Service S.L.</div>
					<div>
						<Trans>Web by </Trans>
						<a href="http://www.peim.es/">Peim!</a>
					</div>
				</span>
			</div>

			{section && section.endsWith("contact/") && (
				<div className="KDBanner">
					<KDBanner className="KDBanner" />
				</div>
			)}
		</footer>
	);
}
