// main imports
import * as React from "react";

// plugin imports

// style imports
import "./MenuMobile.scss";
import { Link, useI18next } from "gatsby-plugin-react-i18next";
import MenuToggle from "./MenuToggle";

// svg imports
// import MenuMask from "../../svg/menuMask.svg";
// import MenuSubBg from "../../svg/menuSubBg.svg";
import MenuSubBgStraight from "../../svg/menuSubBgStraight.svg";

// components imports
import ClientOnly from "../CookiesBanner/ClientOnly";

// component
export default function Menu({ menuItems, menuShow, setMenuShow }) {
	const { changeLanguage, language, languages } = useI18next();

	const closeMenu = () => {
		setMenuShow(false);
	};

	return (
		<>
			{/* <MenuMask /> */}
			<div id="navMenuSub" className={`${menuShow ? "menuShow" : ""}`}>
				<MenuSubBgStraight className="bg" />
			</div>

			<MenuToggle menuShow={menuShow} setMenuShow={setMenuShow} />
			<div id="navMenu" className={`${menuShow ? "menuShow" : ""}`}>
				<button
					id="navMenuUnderlay"
					onClick={() => setMenuShow(false)}
					aria-label="close menu"
				/>

				<div id="menuBg">
					<div className="menuBgLinesContainer">
						{menuItems.map((item, i) => (
							<h2 className="menuBgLine" key={"menuBgLine" + i}>
								&nbsp;
							</h2>
						))}
						<h1 className="menuBgLine">&nbsp;</h1>
					</div>
				</div>

				{menuItems.map((item, i) =>
					item?.external ? (
						<a
							href={item?.link}
							onClick={closeMenu}
							target="_blank"
							rel="noopener noreferrer"
							className="menuIt"
							key={item?.text}
						>
							<h2>{item?.text}</h2>
						</a>
					) : (
						<Link
							to={item?.link}
							onClick={closeMenu}
							className="menuIt"
							key={item?.text}
							activeClassName="activeSection"
						>
							<span className="decoNum">0{i + 1}</span>
							<h2>{item?.text}</h2>
						</Link>
					)
				)}
				<div className="menuIt languages">
					<ClientOnly>
						{languages?.map((lng, i) => (
							<button
								key={lng}
								href="#"
								onClick={(e) => {
									e.preventDefault();
									changeLanguage(lng);
								}}
								className={lng === language ? "activeLang" : ""}
							>
								<h4>{lng}</h4>
							</button>
						))}
					</ClientOnly>
				</div>
			</div>
		</>
	);
}
